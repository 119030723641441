<template>
  <div>
    <div class='text-2xl lg:text-3xl font-semibold uppercase w-full lg:w-1/4'>
      {{ myPageStampTitle }}
    </div>
    <div class='flex flex-col lg:flex-row gap-y-8 lg:gap-x-8 mt-4'>
      <div class='w-full lg:w-1/4 flex flex-col-reverse lg:flex-col gap-y-4'>
        <div class='w-full lg:w-40 h-28 lg:h-40 text-white flex flex-col justify-center items-start p-8'
          :style='`background-color: ${eventMainThemeColor};`'>
          <div class='text-lg uppercase font-medium'>Stamped</div>
          <div class='text-5xl font-normal'>{{myPageDataStampedSponsorsCount}}</div>
        </div>
      </div>
      <div class='w-full lg:w-3/4'>
        <table class='table-fixed w-full text-sm lg:text-base'>
          <thead>
            <tr class='uppercase text-gray-600 whitespace-nowrap uppercase'>
              <th class='py-2 lg:py-4 px-2 text-left w-6 lg:w-8'></th>
              <th class='py-2 lg:py-4 px-2 text-left w-32 lg:w-64'>Level</th>
              <th class='py-2 lg:py-4 px-2 text-left'></th>
              <th class='py-2 lg:py-4 px-2 text-right'>Progress</th>
            </tr>
          </thead>
          <my-page-stamp-row
            v-for='(sponsorsByLevel, index) in myPageDataStampedSponsors'
            :key='`data-for-day-${index}`'
            :sponsors-by-level='sponsorsByLevel' />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyPageStampRow from '@/components/my-page/MyPageStampRow.vue'

export default {
  name: 'MyPageStamp',
  components: {
    MyPageStampRow,
  },
  computed: {
    ...mapGetters('myPages', [
      'myPageDataStampedSponsors',
      'myPageDataStampedSponsorsCount',
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
      'showingEventMyPageConfigurations',
    ]),
    myPageStampConfigs () {
      return this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageStampConfigs ? this.showingEventMyPageConfigurations.myPageStampConfigs : null
    },
    myPageStampTitle () {
      return this.myPageStampConfigs && this.myPageStampConfigs.title ? this.myPageStampConfigs.title : 'Stamp Participation'
    },
  },
}
</script>
