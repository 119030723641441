<template>
  <tbody class='border-t'>
    <tr class='hover:bg-gray-50 cursor-pointer hover:shadow-md'
      @click='toggleOpenDetail'>
      <td class='py-2 lg:py-4 px-2 uppercase'><chevron-double-right-icon size='1.2x'/></td>
      <td class='py-2 lg:py-4 px-2 uppercase font-semibold'>{{sponsorsByLevel.levelName}}<span class='text-sm'> (show details)</span></td>
      <td class='py-2 lg:py-4 px-2'></td>   
      <td class='py-2 lg:py-4 px-2 text-right'>{{ progressString }}</td>     
    </tr>
    <tr v-for='sponsor in sponsorStampedData'
      :key='`sponsor-${sponsor.id}`'
      class='text-sm' >
      <td class='py-2 px-2 uppercase'></td>
      <td class='py-2 px-2 text-sm'>{{ sponsor.name }}</td>
      <td class='py-2 px-2 text-right uppercase'>
        <component :is='stampedIcon(sponsor.stamped)'
                   class='inline-block'
                   :class='stampedIconClass(sponsor.stamped)'>
        </component>
        {{ stampedString(sponsor.stamped) }}
      </td>
      <td class='py-2 px-2 text-right'>{{ stampedDateTimeString(sponsor.stampedAt) }}</td>
    </tr>
  </tbody>
</template>

<script>
import { ChevronDoubleRightIcon } from '@vue-hero-icons/outline'
import { CircleDashedIcon, CircleCheckIcon } from 'vue-tabler-icons'
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'

export default {
  name: 'MyPageStampRow',
  props: [
    'sponsorsByLevel'
  ],
  data () {
    return {
      showDetails: false,
    }
  },
  components: {
    ChevronDoubleRightIcon,
    CircleDashedIcon,
    CircleCheckIcon
  },
  computed: {
    sponsorStampedData () {
      return (this.showDetails) ? this. sponsorsByLevel.listOfSponsors : []
    },
    stampedSponsor () {
      return this.sponsorsByLevel.listOfSponsors.filter((sponsor) => sponsor.stamped)
    },
    progressString () {
      return `${this.stampedSponsor.length} of ${this.sponsorsByLevel.listOfSponsors.length}`
    },
  },
  methods: {
    stampedDateTimeString (date) {
      return date ? DateTimeStringHelpers.toDateTime(date) : ''
    },
    toggleOpenDetail () {
      this.showDetails = !this.showDetails
    },
    stampedIconClass (stamped) {
      return stamped ? 'mr-2' : 'mr-1'
    },
    stampedString (stamped) {
      return stamped ? 'completed' : 'incomplete'
    },
    stampedIcon (stamped) {
      return stamped ? 'Circle-check-icon' : 'CircleDashedIcon'
    },
  },
}
</script>

